import { Container } from '~/components/container';
import { VStack } from '~/components/stack';
import { env } from '~/config/env';
import { LandingCTAButton } from '~/routes/_index/landing-cta-button';
import { cn } from '~/utils/cn';

export function LandingHero() {
  return (
    <div className="bg-card flex items-center justify-center min-h-[calc(100vh_-_theme(spacing.landing-navbar))]">
      <Container>
        <VStack className="items-center gap-4">
          <VStack
            className={cn(
              'text-3xl font-bold text-center gap-2',
              'lg:text-5xl lg:gap-4',
            )}
          >
            <h2>Learn smarter.</h2>
            <h1 className="text-theme">
              Crush{' '}
              {env.VITE_APP === 'ems' ? 'NREMT® Paramedic' : 'USMLE® Step 1'}{' '}
              exam!
            </h1>
          </VStack>
          <p className="text-lg lg:text-xl max-w-[800px] text-center leading-relaxed">
            Unlock your potential with a powerful learning weapon available
            anytime, anywhere! Make sure you will{' '}
            <span className="font-bold">
              pass your{' '}
              {env.VITE_APP === 'ems' ? 'NREMT® Paramedic' : 'USMLE® Step 1'}{' '}
              exam the first time!
            </span>
          </p>
          <div className="py-4 w-full md:w-auto">
            <LandingCTAButton />
          </div>
          {/*<HStack className="items-center gap-2">*/}
          {/*  <img src="/img/landing/students.png" alt="Students" />*/}
          {/*  <span className="font-medium text-muted-foreground">*/}
          {/*    Loved by over 1000 students*/}
          {/*  </span>*/}
          {/*</HStack>*/}
          <div className="flex items-center justify-center">
            <picture className="dark:hidden">
              <source
                media="(max-width: 768px)"
                srcSet={`/img/landing/hero-mobile-${env.VITE_APP}.png`}
              />
              <source
                media="(min-width: 769px)"
                srcSet={`/img/landing/hero-desktop-${env.VITE_APP}-light.png`}
              />
              <img
                src={`/img/landing/hero-desktop-${env.VITE_APP}-light.png`}
                alt="App preview"
              />
            </picture>
            <picture className="hidden dark:block">
              <source
                media="(max-width: 768px)"
                srcSet={`/img/landing/hero-mobile-${env.VITE_APP}.png`}
              />
              <source
                media="(min-width: 769px)"
                srcSet={`/img/landing/hero-desktop-${env.VITE_APP}-dark.png`}
              />
              <img
                src={`/img/landing/hero-desktop-${env.VITE_APP}-dark.png`}
                alt="App preview"
              />
            </picture>
          </div>
        </VStack>
      </Container>
    </div>
  );
}
