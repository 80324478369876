import type { LoaderFunctionArgs } from '@remix-run/node';
import { VStack } from '~/components/stack';
import { LandingConfidence } from '~/routes/_index/landing-confidence';
import { LandingFail } from '~/routes/_index/landing-fail';
import { LandingFeatures } from '~/routes/_index/landing-features';
import { LandingFooter } from '~/routes/_index/landing-footer';
import { LandingHero } from '~/routes/_index/landing-hero';
import { LandingLearn } from '~/routes/_index/landing-learn';
import { LandingNavbar } from '~/routes/_index/landing-navbar';
import { LandingPractice } from '~/routes/_index/landing-practice';
import { LandingPricing } from '~/routes/_index/landing-pricing';
import { LandingResults } from '~/routes/_index/landing-results';
import { LandingReviews } from '~/routes/_index/landing-reviews';
import { LandingTest } from '~/routes/_index/landing-test';
import { authenticator } from '~/services/auth.server';
import { ROUTES } from '~/utils/routes';

function LandingPage() {
  return (
    <VStack>
      <LandingNavbar />
      <LandingHero />
      <LandingPractice />
      <LandingTest />
      <LandingLearn />
      <LandingConfidence />
      <LandingFail />
      <LandingFeatures />
      <LandingResults />
      <LandingPricing />
      <LandingReviews />
      <LandingFooter />
    </VStack>
  );
}

export const shouldRevalidate = () => false;

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: ROUTES.DECKS.BROWSE,
  });

  return null;
}

export default LandingPage;
