import type { PropsWithChildren } from 'react';
import { Container } from '~/components/container';
import { VStack } from '~/components/stack';
import { ThemeAwareImage } from '~/features/theme/theme-aware-image';
import { LandingCTAButton } from '~/routes/_index/landing-cta-button';
import { LANDING_QUESTION_COUNT } from '~/routes/_index/landing-question-count';

export function LandingConfidence() {
  return (
    <Container className="py-16">
      <VStack className="items-center gap-16">
        <h2 className="font-bold text-2xl lg:text-4xl lg:max-w-[480px] text-center !leading-normal">
          Study better, score higher! Feel confident!
        </h2>
        <div className="w-full grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <ConfidenceItem img="exam" title="Master your exam!">
            Engage with {LANDING_QUESTION_COUNT}+ high-yield multiple-choice
            questions, all aligned with the most recent exam guidelines. Check
            your knowledge and improve the gaps before the real exam.{' '}
            <b>
              Skip the stress with MEDizzy’s proven strategy for better
              retention.
            </b>
          </ConfidenceItem>
          <ConfidenceItem
            img="knowledge"
            title="Build medical knowledge and confidence"
          >
            Confidence helps with stress, but it can actually improve your
            scores too.{' '}
            <b>
              Empower yourself with real confidence on a foundation of reliable
              knowledge from reputable medical publications and practitioner
              experience.
            </b>
          </ConfidenceItem>
          <ConfidenceItem
            img="learning"
            title="Make your learning efficient and flexible"
          >
            Between your daily activities, your program, and studying for your
            exam, saying you’re busy is an understatement.{' '}
            <b>Don’t sacrifice your chances of passing</b>. MEDizzy takes
            studying mobile, putting everything you need at your fingertips.
          </ConfidenceItem>
        </div>
        <LandingCTAButton />
      </VStack>
    </Container>
  );
}

interface ConfidenceItemProps {
  img: string;
  title: string;
}

function ConfidenceItem({
  img,
  title,
  children,
}: PropsWithChildren<ConfidenceItemProps>) {
  return (
    <VStack className="gap-6 lg:max-w-[370px] items-center text-center">
      <ThemeAwareImage
        light={`/img/landing/confidence/${img}-light.png`}
        dark={`/img/landing/confidence/${img}-dark.png`}
        alt={title}
        className="max-w-[130px]"
      />
      <h3 className="text-2xl lg:text-3xl font-semibold">{title}</h3>
      <p className="leading-relaxed">{children}</p>
    </VStack>
  );
}
